/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_mobile_analytics_app_id": "c5bba8f48c1e4dec9962aee966da95f2",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "c5bba8f48c1e4dec9962aee966da95f2",
            "region": "eu-central-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "PublicLambdaAPI",
            "endpoint": "https://j9owo8bogb.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        },
        {
            "name": "StripeWebhook",
            "endpoint": "https://w5wx5zay3k.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://gsr6nnbmyndizgz6by554lg5h4.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6ty6ee2wyfckxag5efqbtlg7he",
    "aws_cognito_identity_pool_id": "eu-central-1:4e46b476-82ca-47e7-9743-e208d5b47db4",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_827E92Ajf",
    "aws_user_pools_web_client_id": "5o1saauc7hfds0epl5hbo6fa3a",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "amplify-tasty-s3-files212033-staging",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
